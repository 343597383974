export class MetaFieldsConfig<T> {
    id: number;
    key: string;
    hidden: boolean;
    value: T;
    label: string;
    localeCode : string;
    code : string;
    fieldTypeId: number;
    dataTypeId: number;
    isMandatory: boolean;
    maxLength: number;
    minLength: number;
    displayOrder: number;
    isDisabled: boolean;
    pastDate?:boolean;
    presetDate?:boolean;
    futureDate:boolean;
    options: { id: number; value?: string, localeData?:[] }[];
    constructor(
        options: {
            id?: number;
            key?: string;
            hidden?:boolean;
            value?: T;
            label?: string;
            localeCode ?: string;
            code ?: string;
            fieldTypeId?: number;
            dataTypeId?: number;
            isMandatory?: boolean;
            maxLength?: number;
            minLength?: number;
            displayOrder?: number;
            isDisabled?: boolean;
            pastDate?:boolean;
            presetDate?:boolean;
            futureDate?:boolean;
        } = {}
    ) {
        this.id = options.id || null;
        this.key = options.key;
        this.hidden = !!options.hidden;
        this.value = options.value;
        this.label = options.label || "";
        this.localeCode = options.localeCode || null;
        this.code = options.code || null;
        this.fieldTypeId = options.fieldTypeId || null;
        this.dataTypeId = options.dataTypeId || null;
        this.isMandatory = !!options.isMandatory;
        this.minLength = options.minLength || 1;
        this.maxLength = options.maxLength || 5;
        this.dataTypeId = options.dataTypeId || null;
        this.displayOrder =
            options.displayOrder === undefined ? 1 : options.displayOrder;
        this.isDisabled = !!options.isDisabled;
        this.pastDate = !!options.pastDate;
        this.presetDate = !!options.presetDate;
        this.futureDate = !!options.futureDate;
    }
}
