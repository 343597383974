import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MetaTabsComponent } from "./meta-tabs/meta-tabs.component";
import { MaterialModule } from "../material.module";
import { TabContentComponent } from "./meta-tabs/tab-content.component";
import { ContentContainerDirective } from "./meta-tabs/content-container.directive";
import { EmptyTabComponent } from "./meta-tabs/empty-tab.component";
import { MetaSharedModule } from "../meta-shared";

const components = [
    MetaTabsComponent,
    TabContentComponent,
    ContentContainerDirective,
    EmptyTabComponent,
];
@NgModule({
    declarations: [...components],
    imports: [CommonModule, MaterialModule, MetaSharedModule],
    exports: [...components],
    entryComponents: [EmptyTabComponent],
})
export class MetaTabsModule {}
