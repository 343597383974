import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common'
import { SSOService } from './oauth2/sso/sso.service';
import { ActivatedRoute } from '@angular/router';
import { resolve, reject } from 'q';

@Injectable()
export class StartupService {

    loggin: any;
    private _startupData: any;

    constructor(private http: HttpClient, private route: Location, private ssoService: SSOService) { }

    // This is the method you want to call at bootstrap
    // Important: It should return a Promise
    load(): any {
        this.loggin = this.ssoService.isLoggedIn()
        return new Promise((resolve, reject) =>{
            if (!this.loggin && this.isOtpPage()) {
           //     this.ssoService.obtainAccessToken();
                setTimeout(
                    () => {
    resolve(true);
                    },
                    3000
                )
            }else{
                reject(true)
            }
       
        }).then(data =>{}).catch(err => {})
   

    }
    isOtpPage() {

       let temp = this.route.path().indexOf('onboard-otp') == -1
       if(!this.route.path()){
           temp = false;
       }
       return temp;
    }
    get startupData(): any {
        return this._startupData;
    }
}