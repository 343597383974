import { Component, OnInit } from "@angular/core";
import { Observable, Observer, of } from "rxjs";

@Component({
    selector: "lib-meta-tabs-v2-wrapper",
    templateUrl: "./meta-tabs-v2-wrapper.component.html",
    styles: [],
})
export class MetaTabsV2WrapperComponent implements OnInit {
    asyncTabs: Observable<any[]>;
    constructor() {
        setTimeout(() => {
            this.asyncTabs = of([
                {
                    label: "First",
                    content: null,
                    children: [
                        {
                            label: "First:First",
                            content: null,
                            children: [
                                {
                                    label: "First:First",
                                    content: null,
                                    children: null,
                                },
                                {
                                    label: "First:Second",
                                    content: null,
                                    children: null,
                                },
                            ],
                        },
                        {
                            label: "First:Second",
                            content: null,
                            children: [
                                {
                                    label: "First:First",
                                    content: null,
                                    children: null,
                                },
                                {
                                    label: "First:Second",
                                    content: null,
                                    children: null,
                                },
                            ],
                        },
                    ],
                },
                {
                    label: "Second",
                    content: null,
                    children: [
                        {
                            label: "Second:First",
                            content: null,
                            children: [
                                {
                                    label: "First:First",
                                    content: null,
                                    children: null,
                                },
                                {
                                    label: "First:Second",
                                    content: null,
                                    children: null,
                                },
                            ],
                        },
                        {
                            label: "Second:Second",
                            content: null,
                            children: [
                                {
                                    label: "First:First",
                                    content: null,
                                    children: null,
                                },
                                {
                                    label: "First:Second",
                                    content: null,
                                    children: null,
                                },
                            ],
                        },
                    ],
                },
            ]);
        }, 1000);
    }
    ngOnInit(): void {}
}
