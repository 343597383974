import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
    HttpInterceptor,
    HttpRequest,
    HttpEvent,
    HttpHandler,
    HttpResponse,
    HttpErrorResponse,
    HttpClient,
} from "@angular/common/http";
import { tap } from "rxjs/internal/operators";
import { environment } from "environments/environment";

import { Store } from "@ngrx/store";
import {
    APP_STATUS_SERVICE_UNAVAILABLE,
    APP_STATUS_SERVICE_AVAILABLE,
    SERVICE_UNAVAILABLE,
    SERVICE_AVAILABLE,
} from "shared-store";
import { StorageService } from "dil-shared";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    UNAUTHORIZED_STATUS = 401;
    // TODO: should be removed once we remove the splash screen
    TEMP_REDIRECT_STATUS = 307;
    ip_address = environment.ip_address;
    //host:string = "";
    usrl: string[];
    constructor(
        private _store: Store<any>,
        private router: Router,
        private http: HttpClient,
        private _storageService: StorageService
    ) {
        let hostname = window.location.hostname;
        let host = hostname.split(".")[0];
        console.log(window.location.origin);
        //let relamid:string[] = host.split(".");
        //console.log('realmid', host);
        //console.log(this.excludedUrls);
        this.excludedUrls(host);
    }

    private excludedUrls(host): string[] {
        let urls = (this.usrl = [
            `${this.ip_address}/realms/${host}`,
            `${this.ip_address}/realms/${host}/protocol/openid-connect/auth`,
            `${this.ip_address}/realms/${host}/protocol/openid-connect/token`,
            `${this.ip_address}/realms/${host}/protocol/openid-connect/token/introspect`,
            `${this.ip_address}/realms/${host}/protocol/openid-connect/userinfo`,
            `${this.ip_address}/realms/${host}/protocol/openid-connect/logout`,
            `${this.ip_address}/realms/${host}/protocol/openid-connect/certs`,
            `${this.ip_address}/realms/${host}/protocol/openid-connect/login-status-iframe.html`,
            `${this.ip_address}/realms/${host}/clients-registrations/openid-connect`,
            `${this.ip_address}/realms/${host}/protocol/openid-connect/revoke`,
            `${this.ip_address}/realms/${host}/protocol/openid-connect/auth/device`,
            `${this.ip_address}/realms/${host}/protocol/openid-connect/ext/ciba/auth`,
            `${this.ip_address}/realms/${host}/protocol/openid-connect/ext/par/request`,
            `${this.ip_address}/realms/${host}/.well-known/openid-configuration`,

            // `http://localhost:8080/realms/master`,
            // `http://localhost:8080/realms/master/protocol/openid-connect/auth`,
            // `http://localhost:8080/realms/master/protocol/openid-connect/token`,
            // `http://localhost:8080/realms/master/protocol/openid-connect/token/introspect`,
            // `http://localhost:8080/realms/master/protocol/openid-connect/userinfo`,
            // `http://localhost:8080/realms/master/protocol/openid-connect/logout`,
            // `http://localhost:8080/realms/master/protocol/openid-connect/certs`,
            // `http://localhost:8080/realms/master/protocol/openid-connect/login-status-iframe.html`,
            // `http://localhost:8080/realms/master/clients-registrations/openid-connect`,
            // `http://localhost:8080/realms/master/protocol/openid-connect/revoke`,
            // `http://localhost:8080/realms/master/protocol/openid-connect/auth/device`,
            // `http://localhost:8080/realms/master/protocol/openid-connect/ext/ciba/auth`,
            // `http://localhost:8080/realms/master/protocol/openid-connect/ext/par/request`,
            // `http://localhost:8080/realms/master/.well-known/openid-configuration`,
        ]);
        //console.log(urls);
        return urls;
        //return urls;
    }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let headers = req.headers;
        if (!this.usrl.includes(req.url)) {
            // console.log(environment.domainName);
            console.log("req URL", req.url);
            /**
             * Deligement user management logic
             */
            console.log(this._storageService.vendor);
            let tenantId = this._storageService.tenantId || "1";
            let lang = this._storageService.lang || "1";
            let vendor = this._storageService.vendor || "";
            let username = this._storageService.username || "";
            let role = this._storageService.role || "";
            let accessibleProfile =
                this._storageService.getItem("accessibleProfile") || "";
            let publicKey = this._storageService.getItem("publicKey") || "";
            let bookingLocations =
                this._storageService.getItem("bookingLocations") || "";
            let customerSegment =
                this._storageService.getItem("customerSegment") || "";
            let expiryDate = this._storageService.getItem("expiryDate") || "";
            let phone = this._storageService.getItem("phone") || "";
            // let branchSearch =
            //     this._storageService.getItem("branchSearch") || "";
            let status = this._storageService.getItem("status") || "";
            let reviewType = this._storageService.getItem("reviewType") || "";
            let accessToken = this._storageService.getItem("accessToken") || "";
            let branches = this._storageService.getItem("branches") || "";
            headers = headers
                .set("X-TENANT-ID", tenantId)
                .set("X-LOCALE-ID", lang)
                .set("X-USER-TENANTNAME", vendor)
                .set("X-USER-NAME", username)
                .set("X-USER-ROLE", role)
                .set("X-USER-ACCESSIBLEPROFILE", accessibleProfile)
                .set("X-USER-BOOKINGLOCATIONS", bookingLocations)
                .set("X-USER-BRANCHES", branches)
                .set("X-USER-CUSTOMERSEGMENT", customerSegment)
                .set("X-USER-EXPIRYDATE", expiryDate)
                .set("X-USER-PHONE", phone)
                .set("X-USER-STATUS", status)
                .set("X-USER-REVIEWTYPE", reviewType)
                .set("X-USER-ACCESSTOKEN", accessToken)
                .set("X-USER-PUBLICKEY", publicKey);
            if (!environment.production) {
                //Prod specific headers
            }
        }
        const apiReq = req.clone({
            url: req.url,
            headers,
        });
        return next.handle(apiReq).pipe(
            tap(
                (event: any) => {
                    if (event instanceof HttpResponse) {
                        if (event.status === this.UNAUTHORIZED_STATUS) {
                            this.unauthorized();
                        } else if (event.status === this.TEMP_REDIRECT_STATUS) {
                            // TODO: should be removed once we remove the splash screen
                            // this._store.dispatch({
                            //   type: APP_ACTIONS.SHOW_SPLASH_SCREEN
                            // });
                        } else {
                            this.dispatchServiceAvailable();
                        }
                    }
                },
                (error: any) => {
                    if (error instanceof HttpErrorResponse) {
                        error = error as HttpErrorResponse;
                        if (error.status === this.UNAUTHORIZED_STATUS) {
                            this.unauthorized();
                        } else if (error.status === this.TEMP_REDIRECT_STATUS) {
                            // TODO: should be removed once we remove the splash screen
                            // this._store.dispatch({
                            //   type: APP_ACTIONS.SHOW_SPLASH_SCREEN
                            // });
                        } else if (error.status === 404) {
                            // handling the service unavailable during build
                            // if the error is without a message then treating as service unavailable
                            if (error.error && !error.error.message) {
                                this.dispatchServiceUnavailable();
                            }
                        } else if (
                            error.status === 503 ||
                            error.status === 403
                        ) {
                            // handling the service unavailable
                            if (error.error && error.error.errorCode !== 403) {
                                this.dispatchServiceUnavailable();
                            }
                        }
                    }
                }
            )
        );
    }

    private unauthorized() {
        // navigating to unauthorized page
        this.router.navigate(["/unauthorized"]);
    }

    private dispatchServiceUnavailable() {
        this._store.dispatch(
            APP_STATUS_SERVICE_UNAVAILABLE({
                payload: { data: SERVICE_UNAVAILABLE },
            })
        );
    }

    private dispatchServiceAvailable() {
        this._store.dispatch(
            APP_STATUS_SERVICE_AVAILABLE({
                payload: { data: SERVICE_AVAILABLE },
            })
        );
    }
}
