import { MetaFieldsConfig } from "./meta-fields.config";
export class MetaMultiselect extends MetaFieldsConfig<any> {
    fieldTypeId = 104;
    options: { id: number; value?: string, localeData ?: [] }[] = [];

    constructor(options: {} = {}) {
        super(options);
        this.options = options["options"] || [];
    }
}
