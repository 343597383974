import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MetaFieldsConfig } from "../meta-fields.config";
import { FormGroup } from "@angular/forms";

@Component({
    selector: "lib-meta-fields",
    templateUrl: "./meta-fields.component.html",
    styleUrls: ["./meta-fields.component.css"],
})
export class MetaFieldsComponent {
    @Input() localeId = 1;
    @Input() field: MetaFieldsConfig<string>;
    @Input() form: FormGroup;
    @Input() disable:any;
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    handleChange($event, field) {
        const value = $event.value || null;
        this.onChange.emit({ field, value });
    }
    updateChange() {
        this.onChange.emit({ data: true });
    }
    dateFilter = (d: Date): boolean => {
        const today = new Date();
        const { pastDate, presetDate, futureDate } = this.field;
        if (d < today && !pastDate) return false;
        else if (d === today && !presetDate) return false;
        else if (d > today && !futureDate) return false;
        return true;
    };

    filterSearch(searchedText, value) {

        if (!value) {
            return false;
        }
        return (
            searchedText &&
            !value.toLowerCase().includes(searchedText.toLowerCase())
        );
    }

}
