import { Injectable } from "@angular/core";
import { defaults } from "./diligent-config/config";

interface IStrategy {
    strategy?: Storage;
}
@Injectable({
    providedIn: "root",
})
export class StorageService {
    _defaults: any;
    private storageStrategy: Storage = localStorage;
    constructor() {}
    init(obj: IStrategy = {}) {
        this.storageStrategy = obj.strategy || localStorage;
    }
    getAllStorageData() {
        let vendor = this.storageStrategy.getItem("vendor");
        let language = this.storageStrategy.getItem("language");
        let lang = this.storageStrategy.getItem("lang");
        let tenant = this.storageStrategy.getItem("tenant");
        let username = this.storageStrategy.getItem("username");
        let role = this.storageStrategy.getItem("role");
        let roleId = this.storageStrategy.getItem("roleId");
        let accessibleProfile =
            this.storageStrategy.getItem("accessibleProfile");
        let bookingLocations = this.storageStrategy.getItem("bookingLocations");
        let regions = this.storageStrategy.getItem("regions");
        let cities = this.storageStrategy.getItem("cities");
        let branches = this.storageStrategy.getItem("branchs");
        let customerSegment = this.storageStrategy.getItem("customerSegment");
        let expiryDate = this.storageStrategy.getItem("expiryDate");
        let phone = this.storageStrategy.getItem("phone");
        let reviewType = this.storageStrategy.getItem("reviewType");
        let branchSearch = this.storageStrategy.getItem("branchSearch");
        let status = this.storageStrategy.getItem("status");
        let accessToken = this.storageStrategy.getItem("accessToken");
        let roleDisplayOrder = this.storageStrategy.getItem("roleDisplayOrder");
        let publicKey = this.storageStrategy.getItem("publicKey");
        let roleList = this.storageStrategy.getItem("roleList");
        return {
            vendor,
            language,
            lang,
            tenant,
            username,
            role,
            roleList,
            roleId,
            accessibleProfile,
            bookingLocations,
            regions,
            cities,
            branches,
            customerSegment,
            expiryDate,
            phone,
            reviewType,
            branchSearch,
            status,
            accessToken,
            roleDisplayOrder,
            publicKey,
        };
    }
    languageWrapper(language = "1") {
        let lang;
        if (language == "1") {
            lang = "en";
        } else if (language == "2") {
            lang = "cn";
        } else if (language == "3") {
            lang = "fr";
        }
        return lang;
    }
    get defaultSettings() {
        return this._defaults;
    }
    setdefaultSettings(config) {
        // console.log(config)
        for (let name in config) {
            console.log(name);
            this[name] = config[name];
        }
        // console.log(this)
    }
    tenantWrapper(tenant = "1") {
        let vendor;
        if (tenant == "1") {
            vendor = "hdfc";
        } else if (tenant == "2") {
            vendor = "icici";
        }
        return vendor;
    }

    setItem(key, value) {
        this.storageStrategy.setItem(key, value);
    }

    getItem(key) {
        return this.storageStrategy.getItem(key);
    }

    get vendor() {
        return this.storageStrategy.getItem("vendor");
    }

    get accessRights() {
        return this.storageStrategy.getItem("accessRights");
    }

    get language() {
        return this.storageStrategy.getItem("language") || "en";
    }

    get languages() {
        return JSON.parse(this.storageStrategy.getItem("languages")) || ["en"];
    }

    get lang() {
        return this.storageStrategy.getItem("lang") || "1";
    }

    get tenantId() {
        return this.storageStrategy.getItem("tenantId") || "1";
    }

    get username() {
        return this.storageStrategy.getItem("username") || "";
    }

    get role() {
        return this.storageStrategy.getItem("role") || null;
    }

    get roleList() {
        return this.storageStrategy.getItem("roleList") || null;
    }

    get roleId() {
        return this.storageStrategy.getItem("roleId") || "";
    }

    get accessibleProfile() {
        return this.storageStrategy.getItem("accessibleProfile") || "";
    }

    get bookingLocations() {
        return this.storageStrategy.getItem("bookingLocations") || "";
    }

    get regions() {
        return this.storageStrategy.getItem("regions") || "";
    }

    get cities() {
        return this.storageStrategy.getItem("cities") || "";
    }

    get branches() {
        return this.storageStrategy.getItem("branches") || "";
    }

    get customerSegment() {
        return this.storageStrategy.getItem("customerSegment") || "";
    }

    get expiryDate() {
        return this.storageStrategy.getItem("expiryDate") || "";
    }

    get phone() {
        return this.storageStrategy.getItem("phone") || "";
    }

    get reviewType() {
        return this.storageStrategy.getItem("reviewType") || "";
    }

    get branchSearch() {
        return this.storageStrategy.getItem("branchSearch") || "";
    }

    get status() {
        return this.storageStrategy.getItem("status") || "";
    }

    get accessToken() {
        return this.storageStrategy.getItem("accessToken") || "";
    }

    get publicKey(): any {
        return this.storageStrategy.getItem("publicKey");
    }

    get roleDisplayOrder() {
        return this.storageStrategy.getItem("roleDisplayOrder") || "";
    }

    set vendor(vendor) {
        this.storageStrategy.setItem("vendor", vendor);
    }

    set accessRights(accessRights) {
        this.storageStrategy.setItem("accessRights", accessRights);
    }

    set language(language) {
        this.storageStrategy.setItem("language", language);
    }

    set languages(languages) {
        this.storageStrategy.setItem("languages", JSON.stringify(languages));
    }

    set lang(lang) {
        this.storageStrategy.setItem("lang", lang);
    }

    set tenantId(tenant) {
        this.storageStrategy.setItem("tenantId", tenant);
    }

    set username(username) {
        this.storageStrategy.setItem("username", username);
    }

    set accessibleProfile(accessibleProfile) {
        this.storageStrategy.setItem("accessibleProfile", accessibleProfile);
    }

    set bookingLocations(bookingLocations) {
        this.storageStrategy.setItem("bookingLocations", bookingLocations);
    }

    set regions(regions) {
        this.storageStrategy.setItem("regions", regions);
    }

    set cities(cities) {
        this.storageStrategy.setItem("cities", cities);
    }

    set branches(branches) {
        this.storageStrategy.setItem("branches", branches);
    }

    set customerSegment(customerSegment) {
        this.storageStrategy.setItem("customerSegment", customerSegment);
    }

    set expiryDate(expiryDate) {
        this.storageStrategy.setItem("expiryDate", expiryDate);
    }

    set phone(phone) {
        this.storageStrategy.setItem("phone", phone);
    }

    set reviewType(reviewType) {
        this.storageStrategy.setItem("reviewType", reviewType);
    }

    set branchSearch(branchSearch) {
        this.storageStrategy.setItem("branchSearch", branchSearch);
    }
    set status(status) {
        this.storageStrategy.setItem("status", status);
    }

    set roleList(roleList) {
        this.storageStrategy.setItem("roleList", roleList);
    }

    set accessToken(accessToken) {
        this.storageStrategy.setItem("accessToken", accessToken);
    }

    set role(role) {
        this.storageStrategy.setItem("role", role);
    }

    set roleId(roleId) {
        this.storageStrategy.setItem("roleId", roleId);
    }

    set roleDisplayOrder(roleDisplayOrder) {
        this.storageStrategy.setItem("roleDisplayOrder", roleDisplayOrder);
    }

    set publicKey(publicKey) {
        this.storageStrategy.setItem("publicKey", publicKey);
    }

    removeItem(item) {
        this.storageStrategy.removeItem(item);
    }

    get length() {
        return this.storageStrategy.length;
    }

    key(index) {
        return this.storageStrategy.key(index);
    }

    clear() {
        this.storageStrategy.clear();
    }
}
