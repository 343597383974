import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-health-check",
    template: "<p>OK</p>",
})
export class HealthCheckComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
