import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { of, Observable } from "rxjs";

@Component({
    selector: "lib-meta-upload-demo",
    templateUrl: "./meta-upload-demo.component.html",
    styles: [],
})
export class MetaUploadDemoComponent implements OnInit {
    fileType = ".doc,.docx,.pdf";
    fileToUpload: String;

    constructor() {}

    ngOnInit(): void {}

    onSubmit() {}

    onFileUpload(e) {
        this.fileToUpload = e.item(0).name;
    }
}
