import { Component, OnInit } from "@angular/core";
import { Observable, of } from "rxjs";
import { TabService } from "./tab.service";
import { MetaTab } from "../../components/meta-tabs/meta-tabs/meta-tab.model";

@Component({
    selector: "lib-meta-tabs-demo",
    templateUrl: "./meta-tabs-demo.component.html",
    styleUrls: ["./meta-tabs-demo.component.scss"],
})
export class MetaTabsDemoComponent implements OnInit {
    constructor(private tabService: TabService) {}

    tabs$: Observable<MetaTab[]> = null;
    ngOnInit() {
        this.tabs$ = this.tabService.getTabs();
    }
}
