import { Injectable } from "@angular/core";
import {
    FormControl,
    FormGroup,
    Validators,
    FormArray,
    ValidatorFn,
    AbstractControl,
} from "@angular/forms";
import * as moment from "moment";
import { AccessRights } from "projects/onboarding/src/lib/corporate/constants/access-permission.constants";

@Injectable()
export class MetaFormsControlService {
    newControl(fieldTypeId, input = "", isMandatory, isDisabled, dataTypeId) {
        const disabled = isDisabled;
        const value =
            fieldTypeId === 106 ? (input ? moment(input) : input) : input;
        return isMandatory
            ? new FormControl(
                  { value: value !== null ? value : "", disabled: isDisabled },
                  [Validators.required, this.customValidator(dataTypeId)]
              )
            : new FormControl(
                  { value: value !== null ? value : "", disabled: isDisabled },
                  this.customValidator(dataTypeId)
              );
    }
    toFormArray(
        groups: any[],
        flag: Boolean,
        rolePermissions?: AccessRights
    ): FormGroup {
        // toFormArray(questions: MetaField<string>[]) {
        let conditionalArray: any = {};
        const group = {};
        groups.forEach((question) => {
            const array = question.items.map((item, index) => {
                const { conditionalFieldListItems = [] } = item;
                const { compositeFieldListItems = [] } = item;
                const compositeArray = compositeFieldListItems.map((field) => {
                    const {
                        compositeDetails: {
                            additionalConfig: [config] = [
                                { isMandatory: true },
                            ],
                        } = {},
                    } = field;
                    return new FormGroup({
                        [field.id]: this.newControl(
                            config.fieldTypeId,
                            field.value,
                            config.isMandatory,
                            rolePermissions.readOnly,
                            config.dataTypeId
                        ),
                    });
                });
                conditionalArray = conditionalFieldListItems.map((field) => {
                    const {
                        conditionalDetails: {
                            additionalConfig: [config] = [
                                { isMandatory: true },
                            ],
                        } = {},
                    } = field;
                    return new FormGroup({
                        [field.id]: this.newControl(
                            config.fieldTypeId,
                            field.value,
                            config.isMandatory,
                            rolePermissions.readOnly,
                            config.dataTypeId
                        ),
                    });
                });
                // if (conditionalFieldListItems.length && flag) {
                //     console.log(item.fieldId);
                //     conditional[item.value] = new FormGroup({
                //         [item.id]: new FormArray(conditionalArray),
                //     });

                // } else
                if (compositeFieldListItems.length) {
                    return (group[item.fieldId] = new FormGroup({
                        [item.id]: new FormArray(compositeArray),
                    }));
                } else {
                    return new FormGroup({
                        [item.id]: this.newControl(
                            question.fieldTypeId,
                            item.value,
                            question.isMandatory,
                            rolePermissions.readOnly,
                            question.dataTypeId
                        ),
                    });
                }
            });
            if (!flag) {
                group[question.id] = new FormArray(array);
            } else {
                group[question.items[0].value] = new FormArray(
                    conditionalArray
                );
            }
        });
        return new FormGroup(group);
    }
    customValidator(dataType): ValidatorFn {
        return (c: AbstractControl): { [key: string]: any } | null => {
            if (c.value !== "") {
                if (dataType == 202) {
                    const regExpAll = /^([a-zA-Z0-9 _-]+)$/;
                    if (!regExpAll.test(c.value)) {
                        return { allDataType: true };
                    } else {
                        return null;
                    }
                } else if (dataType == 203) {
                    const regExpAll = /^[0-9]+$/;
                    if (!regExpAll.test(c.value)) {
                        return { numberDataType: true };
                    } else {
                        return null;
                    }
                } else if (dataType == 204) {
                    const regExpAll = /^[a-zA-Z ]+$/;
                    if (!regExpAll.test(c.value)) {
                        return { alphabetDataType: true };
                    } else {
                        return null;
                    }
                }else if (dataType == 205) {
                    const regExpAll = /^[0-9. ]+$/;
                    if (!regExpAll.test(c.value)) {
                        return { decimalType: true };
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            }
        };
    }
}
