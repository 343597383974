import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import { Observable, of } from "rxjs";
import {
    MetaFieldsConfig,
    MetaTextbox,
    MetaTextarea,
    MetaDatepicker,
    MetaSelect,
    MetaMultiselect,
    MetaFieldsControlService,
    MetaCheckbox,
    MetaCheckboxConfig,
    MetaLabel,
} from "../../components/meta-fields";

@Component({
    selector: "lib-meta-table-demo",
    templateUrl: "./meta-table-demo.component.html",
    styleUrls: ["./meta-table-demo.component.css"],
})
export class MetaTableDemoComponent implements OnInit, AfterViewInit {
    constructor(private _metaFieldsControlService: MetaFieldsControlService) {}
    @ViewChild("noTempRef") noTemp;
    @ViewChild("yesTempRef") yesTemp;
    table = {};
    ngAfterViewInit() {
        this.table = {
            th: ["FIeld Name", "Full Legal Name"],
            tr: [
                [
                    new MetaCheckbox({
                        label: "Memorandum and article of associtation",
                        checked: true,
                    }),
                    new MetaCheckbox({
                        template: this.yesTemp,
                        checked: true,
                    }),
                ],
                [
                    new MetaCheckbox({
                        label: "Australia Securities",
                        checked: false,
                    }),
                    new MetaCheckbox({
                        template: this.yesTemp,
                        checked: false,
                    }),
                ],
                [
                    new MetaCheckbox({
                        label: "Certificate of good standing",
                        checked: true,
                    }),
                    new MetaLabel({
                        template: this.noTemp,
                    }),
                ],
            ],
        };
    }

    table2 = {
        th: ["Document name", "Mandatory", "Cerficiate Reqirements"],
        tr: [
            [
                {
                    checkbox: { visible: false, checked: true },
                    label: "Goverment approved documents",
                },
                {
                    label: "Yes",
                },
                {
                    label: "Other Documents",
                },
            ],
        ],
    };

    form: FormGroup;
    payLoad = "";
    fields$: Observable<MetaFieldsConfig<any>[]>;

    connectedPartiesFields = null;

    documentTrackingHeaders = [
        "",
        "Doc Type",
        "Doc Status",
        "Target Date",
        "Remain Current?",
        "Expiry Date",
        "Attach",
        "Uploaded By",
        "Uploaded On",
        "Comments",
    ];
    ngOnInit(): void {
        const fields: MetaFieldsConfig<string>[] = [
            new MetaSelect({
                id: 4,
                label: "Doc Type",
                key: "docType",
                value: "A",
                isMandatory: true,
                options: [
                    { id: 1, value: "A" },
                    { id: 2, value: "B" },
                ],
            }),
            new MetaSelect({
                id: 5,
                label: "Doc Status",
                key: "docStatus",
                value: "B",
                isMandatory: true,
                options: [
                    { id: 1, value: "A" },
                    { id: 2, value: "B" },
                ],
            }),
            new MetaDatepicker({
                id: 3,
                label: "Target Date",
                key: "targetDate",
                value: "",
                isMandatory: true,
            }),
            new MetaSelect({
                id: 5,
                label: "Remain Current?",
                key: "remainCurrent",
                value: "B",
                isMandatory: true,
                options: [
                    { id: 1, value: "A" },
                    { id: 2, value: "B" },
                ],
            }),
            new MetaDatepicker({
                id: 3,
                label: "Expiry Date",
                key: "expiryDate",
                value: "",
                isMandatory: true,
            }),
            new MetaTextbox({
                id: 1,
                label: "Attach",
                key: "attach",
                value: "",
                isMandatory: true,
            }),
            new MetaTextbox({
                id: 1,
                label: "Uploaded By",
                key: "uploadedBy",
                value: "",
                isMandatory: true,
            }),
            new MetaTextbox({
                id: 1,
                label: "Uploaded On",
                key: "uploadedOn",
                value: "",
                isMandatory: true,
            }),
            new MetaTextarea({
                id: 2,
                label: "Comments",
                key: "comments",
                value: "Bombasto",
                isMandatory: true,
            }),
            // new MetaMultiselect({
            //     id: 5,
            //     label: "Hobbies",
            //     key: "hobbies",
            //     value: "Cricket,Football".split(","),
            //     isMandatory: true,
            //     options: [
            //         { id: 1, value: "Cricket" },
            //         { id: 2, value: "Football" },
            //         { id: 3, value: "Chess" },
            //     ],
            // }),
        ];
        this.fields$ = of(
            fields.sort((a, b) => a.displayOrder - b.displayOrder)
        );
        this.connectedPartiesFields = {
            nameScreening: new MetaDatepicker({
                id: 3,
                label: "Name Screening Date",
                key: "nameScreening",
                value: "",
                isMandatory: true,
            }),
        };
        this.form = new FormGroup({
            documentation: new FormGroup({
                documentTracking: new FormArray([
                    this._metaFieldsControlService.toFormGroup(fields),
                    this._metaFieldsControlService.toFormGroup(fields),
                    this._metaFieldsControlService.toFormGroup(fields),
                    this._metaFieldsControlService.toFormGroup(fields),
                    this._metaFieldsControlService.toFormGroup(fields),
                ]),
            }),
            connectedParties: new FormGroup({
                nameScreening: this._metaFieldsControlService.toFormGroup([
                    this.connectedPartiesFields.nameScreening,
                ]),
            }),
        });
    }
    onSubmit() {
        this.payLoad = JSON.stringify(this.form.getRawValue());
    }
}
