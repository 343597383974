import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MetaTableComponent } from "./meta-table/meta-table.component";

const components = [MetaTableComponent];

@NgModule({
    declarations: [...components],
    imports: [CommonModule],
    exports: [...components],
})
export class MetaTableModule {}
