import { FormControl, Validators } from "@angular/forms";

export class IdvCheckListModel {
    customerNames: any;
    ownershipType: any = [];
    entityType: any = [];
    subEntityType: any = [];
    bookingLocation: any = [];
    countryIncorporation: any = [];
    riskRating: any = [];
    specializedDD: any = [];
     
}

export class CustomerRelatedChecklistData {
    id: number = null;
    tenantId: number = null;
    customerName: string = "raja";
    transactionReference:string = null;
    riskRatingId: string = null;
    reviewType: number = null;
    sourceSystem:string=null;
    locale: string = "en";
    specialDDList: SpecialDDList[];
    customerRelatedPartyMappingList: RelatedTabConfig[] = [];
    customerSegId: any;
    constructor(obj: any = {}) {}
}
export class SpecialDDList {
    id: number = null;
    specialDDId: number = null;
    documentInfo: DocType = new DocType({});
    constructor(splId) {
        this.specialDDId = splId;
    }
}
export class RelatedTabConfig {
    relatedPartyFieldDocumentMappingList: RelatedPartyFieldDocumentMappingDTOList[] =
        [new RelatedPartyFieldDocumentMappingDTOList()];

    constructor(obj: any = {}) {
        if (obj.relatedPartyFieldDocumentMappingList) {
            this.relatedPartyFieldDocumentMappingList =
                obj.relatedPartyFieldDocumentMappingList;
        }
    }
}
export class BookingLocList {
    bookingLocationId: number = null;
    constructor(obj: any = {}) {
        this.bookingLocationId = obj.bookingLocationId || null;
    }
}

export class RelatedPartyFieldDocumentMappingDTOList {
    id: any = null;
    relatedPartyId: number = 2;
    designationId: number = 3025;
    orgTypeId: string = null;
    ownershipTypeId: string = null;
    entityTypeId: string = null;
    subEntityTypeId: string = null;
    orgTypeVersion: string = null;
    coiNationalityId: string = null;
    name: string = "test1";
    email: string = "test@gmail.com";
    phoneNo: string = null;
    custmerId: number = null;
    customerRelatedPartyBookingLocList: BookingLocList[] = [
        new BookingLocList(),
    ];
    relatedPartyFieldConfigFieldInfoList: FieldData[] = [new FieldData()];
    relatedPartyDocConfigFieldInfoList: DocConfigModel[] = [
        new DocConfigModel(),
    ];
    otherDocumentsList: OtherDocumentsList[] = [new OtherDocumentsList()];
    uniqueIdentifier: any;
    constructor(obj: any = {}) {
        if (obj.customerRelatedPartyBookingLocList) {
            this.customerRelatedPartyBookingLocList =
                obj.customerRelatedPartyBookingLocList;
        }
        this.relatedPartyId = obj.relatedPartyId;
        this.designationId = obj.designationId || null;
        this.name = obj.name || "";
        this.email = obj.email || "";
        this.phoneNo = obj.phoneNo || null;
        this.coiNationalityId = obj.coiNationalityId || null;
        this.orgTypeId = obj.orgTypeId || null;
        this.ownershipTypeId = obj.ownershipTypeId;
        this.entityTypeId = obj.entityTypeId;
        this.subEntityTypeId = obj.subEntityTypeId;
        this.orgTypeVersion = obj.orgTypeVersion;
        this.relatedPartyFieldConfigFieldInfoList =
            obj.relatedPartyFieldConfigFieldInfoList;
        this.relatedPartyDocConfigFieldInfoList =
            obj.relatedPartyDocConfigFieldInfoList;
        this.otherDocumentsList = obj.otherDocumentsList;
        this.uniqueIdentifier = obj.uniqueIdentifier;
    }
}
export class DocType {
    path: string = "";
    mimeType: string = "";
    fileName: string = "";
    dmsDocumentId: string;
    fileExtension: string;

    constructor(obj: any = {}) {
        this.path = obj.path || "";
        this.mimeType = obj.mimeType || "";
        this.fileName = obj.fileName || "";
        this.dmsDocumentId = obj.dmsDocumentId || "";
        this.fileExtension = obj.fileExtension || "";
    }
}
export class DocConfigModel {
    documentId: number = null;
    fieldList: number[] = [];
    documentInfo: DocType = new DocType();
    constructor(obj: any = {}) {
        this.documentId = obj.documentId || null;
        this.fieldList = obj.fieldList || [];
        this.documentInfo = obj.documentInfo || {};
    }
}

export class FieldData {
    fieldId: number = null;
    value: string = "";
    constructor(obj: any = {}) {
        console.log;
        this.fieldId = obj.fieldId || null;
        this.value = obj.value || "";
    }
}

export class FileUploadSnippet {
    pending: boolean = false;
    status: string = "init";
    constructor(public src: string, public file: File) {}
}

export class ContentLocale {
    corpId: any;
    documentId: any;
    name: string;
    localeId: string;
    id: number = null;
    localeData: any = [];
    mnemonicCode: any;
    displayOrder: any;
    isDisabled: boolean;
    text: string;
    constructor(obj, langId = "1", docId = null) {
        if (obj.localeData && obj.localeData.length) {
            let locale = obj.localeData.filter(
                (item) => item.localeId == langId
            );
            if (locale.length) {
                this.name = locale[0].name;
                this.text = locale[0].name;
            } else {
                let locale = obj.localeData.filter(
                    (item) => item.localeId == "1"
                );
                if (locale.length) {
                    this.name = locale[0].name;
                    this.text = locale[0].name;
                } else {
                    this.name = "";
                }
            }
            this.isDisabled = obj.isDisabled;
            this.localeData = obj.localeData;
        }
        this.corpId = obj.corporationId;
        this.localeId = langId;
        this.mnemonicCode = obj.mnemonicCode;
        this.displayOrder = obj.displayOrder;
        this.id = obj.id;
        if (docId) {
            this.documentId = docId;
        }
    }
}
export class OtherDocumentsFormModel {
    isMandatory = new FormControl("", Validators.required);
    otherDocDescription = new FormControl("");
    otherDocName: FormControl = new FormControl("", [Validators.required]);
    constructor(depObj: any) {
        if (depObj.isMandatory != undefined) {
            this.isMandatory.setValue(depObj.isMandatory);
        }
        if (depObj.otherDocName) {
            this.otherDocName.setValue(depObj.otherDocName);
        }
        if (depObj.otherDocDescription) {
            this.otherDocDescription.setValue(depObj.otherDocDescription);
        }
    }
}
export class OtherDocumentsList {
    id: number = null;
    documentInfo: DocType = new DocType({});
    isMandatory: boolean = false;
    description: string = "";
    documentName: string = "";
    constructor(obj: any = {}) {
        this.isMandatory = obj.isMandatory;
        this.description = obj.otherDocDescription;
        this.documentName = obj.otherDocName;
    }
}

export class MandatoryRiskFieldsModel {
    id: number = null;
    tenantId: DocType = new DocType({});
    customerSegmentTypeId: boolean = false;
    riskRatingId: string = "";

    constructor(obj: any = {}) {
        this.id = obj.id;
        this.tenantId = obj.tenantId;
        this.customerSegmentTypeId = obj.customerSegmentTypeId;
        this.riskRatingId = obj.riskRatingId;
    }
}

export class MandatoryRiskRatings {
    id: number = null;
    tenantId: DocType = new DocType({});
    customerSegmentTypeId: boolean = false;
    options: any = [];
    name: string = "";
    localeData: any = [];
    fieldTypeId: number = null;
    mnemonicCode: string = "";

    constructor(obj: any = {}, options: any, langId = "1") {
        this.id = obj.id;
        this.tenantId = obj.tenantId;
        this.customerSegmentTypeId = obj.customerSegmentTypeId;
        this.options = options;
        this.localeData = obj.localeData;
        this.fieldTypeId = obj.additionalConfig[0].fieldTypeId;
        this.mnemonicCode = obj.mnemonicCode;

        if (obj.localeData && obj.localeData.length) {
            let locale = obj.localeData.filter(
                (item) => item.localeId == langId
            );
            if (locale.length) {
                this.name = locale[0].name;
            } else {
                let locale = obj.localeData.filter(
                    (item) => item.localeId == "1"
                );
                if (locale.length) {
                    this.name = locale[0].name;
                } else {
                    this.name = "";
                }
            }
        }
    }
}
