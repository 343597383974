import { Injectable } from "@angular/core";
import {
    FormControl,
    FormGroup,
    Validators,
    FormArray,
    ValidationErrors,
    AbstractControl,
} from "@angular/forms";

export interface MetaFormValidationErrors {
    fieldId: string;
    control_name: string;
    error_name: string;
    error_value: any;
}

export interface MetaFormGroupControls {
    [key: string]: AbstractControl;
}

@Injectable()
export class MetaFormsErrorsService {
    getFormErrors(controls: MetaFormGroupControls): MetaFormValidationErrors[] {
        const errors: MetaFormValidationErrors[] = [];
        Object.keys(controls).forEach((key) => {
            const control = controls[key];
            if (control instanceof FormArray) {
                control.controls.map((items: FormGroup) => {
                    Object.keys(items.controls).forEach((itemKey) => {
                        const childItems = items.get(itemKey) as FormArray;
                        if (childItems.controls && childItems.controls.length) {
                            childItems.controls.map((childItem: FormGroup) => {
                                const childControls = childItem.controls;
                                Object.keys(childControls).forEach(
                                    (childItemKey) => {
                                        const controlErrors: ValidationErrors =
                                            childControls[childItemKey].errors;
                                        if (controlErrors && controlErrors !== null) {
                                            Object.keys(controlErrors).forEach(
                                                (keyError) => {
                                                    errors.push({
                                                        fieldId: key,
                                                        control_name: childItemKey,
                                                        error_name: keyError,
                                                        error_value:
                                                            controlErrors[
                                                                keyError
                                                            ],
                                                    });
                                                }
                                            );
                                        }
                                    }
                                );
                            });
                        } else {
                            const controlErrors: ValidationErrors =
                                items.controls[itemKey].errors;
                            if (controlErrors && controlErrors !== null) {
                                Object.keys(controlErrors).forEach(
                                    (keyError) => {
                                        errors.push({
                                            fieldId: key,
                                            control_name: itemKey,
                                            error_name: keyError,
                                            error_value:
                                                controlErrors[keyError],
                                        });
                                    }
                                );
                            }
                        }
                    });
                });
            }
        });
        return errors;
    }
}
