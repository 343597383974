import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TriggerEventComponent } from './trigger-event.component';
import { CustomerTriggerEventComponent } from './customer-trigger-event/customer-trigger-event.component';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray, ReactiveFormsModule, FormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import {  MatPaginatorModule } from '@angular/material/paginator';
import {MatAutocompleteModule} from '@angular/material/autocomplete';


const routes: Routes = [
  {
    path      : '', redirectTo: 'customer-trigger-event', pathMatch:'full'},
  { path: 'corporate-trigger-event', component: CustomerTriggerEventComponent },
  { path: 'retail-trigger-event', component: CustomerTriggerEventComponent }
];


@NgModule({
  declarations: [TriggerEventComponent, CustomerTriggerEventComponent],
  imports:[RouterModule.forChild(routes),ReactiveFormsModule,
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    TranslateModule,
    MatButtonModule,
    MatTabsModule,
    MatGridListModule,
    MatInputModule,
    MatTableModule,
    MatIconModule,
    MatDialogModule,
    MatToolbarModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTooltipModule,
    NgxSpinnerModule,
    MatPaginatorModule,
    MatAutocompleteModule],
  exports: [TriggerEventComponent]
})
export class TriggerEventModule { }
